<template>
  <div class="wrapper">
    <Header>
      <template #right-header-component>
        <ProfileDropDown/>
      </template>
    </Header>
    <div class="md:tw-w-9/12 tw-p-6	tw-pt-28" style="margin: 0 auto">
      <div class="tw-flex tw-cursor-pointer " @click="$router.go(-1)">
        <vue-feather type="arrow-left"></vue-feather>
        <h2>Back</h2>
      </div>
      <div class="heading-text tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
        <div>
          <h3>Skill Assessment</h3>
          <span>Please note that you can only select one skill assessment</span>
        </div>
        <BaseButton :loading="buttonLoader" @click="$emit('addTest')" class="tw-hidden md:tw-block tw-capitalize" button-text="Add Test"></BaseButton>
      </div>
      <div class="tw-my-8">
        <v-divider></v-divider>
      </div>

      <div class="scroll tw-py-6 md:tw-px-20">
        <div class="tw-pb-10 tw-pt-5 tw-ml-7">
          <v-text-field v-model="search"
                        hide-details
                        style="width: 352px"
                        placeholder="Search a skill"
                        solo
          />
        </div>
        <div class="tw-flex tw-flex-wrap">
          <div class="tw-mx-7 tw-my-5" v-for="(skill,i) in skillsList" @click="selectSkill(skill)" :class="[skill === selectedSkill ? 'selectedSkill': '']" :key="i">
            <v-card flat class="card tw-py-4 tw-px-7">
              <h4 class="name">{{ skill.name }}</h4>
              <div v-if="skill.duration" class="tw-flex align-center tw-pt-1.5">
                <vue-feather type="clock" class="tw-mr-2" style="width: 15px"/>
                <span class="duration">{{getDuration(skill.duration) }}</span>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import BaseButton from "@/components/UIComponents/BaseButton";
import {mapGetters} from "vuex";
export default {
  name: "SkillAssessment",
  components: {BaseButton, ProfileDropDown,Header},
  props: {
    buttonLoader:[Boolean],
  },
  data(){
    return{
      closeOnClick: true,
      search: '',
      skillsList: [],
      selectedSkill: ""
    }
  },
 async created() {
    await this.$store.dispatch('jobScreeningCriteria/getExternalSkills', { site_name:this.$siteName(), id: this.$route.query.jobId})
  },
  computed: {
    ...mapGetters('jobScreeningCriteria', ['getSkills'])
  },
  watch:{
    getSkills: {
      handler(val) {
        this.skillsList = val
      },
      immediate: true
    },
    search: function (newValue, oldValue) {
      console.log(oldValue)
      this.skillsList = [...this.getSkills.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
    }
  },
  methods: {
    getDuration(val) {
      let duration
      if (+val / 60 < 1) {
        duration = val + ' mins'
      } else {
        let hr = val / 60
        let remainder = val % 60
        duration = (Math.round(hr) + (hr >= 2 ? ' hrs' : ' hr')) + ' ' + (remainder + (remainder > 1 ? ' mins' : ' min'))
      }
      return duration
    },
    selectSkill(skill) {
      sessionStorage.setItem('skill', JSON.stringify(skill.name))
      this.selectedSkill = skill
      this.$emit('addQuestion', skill)
    },
  }

}
</script>

<style scoped lang="scss">
.wrapper{
  /*max-height: 95vh;*/
  /*overflow: hidden;*/
}
.heading-text h3{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #142E70;
  margin-bottom: 10px;
  margin-top: 15px;
}
.heading-text span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
.card {
  width: 309.5px;
  min-height: 78px;
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  border-radius: 8px;
}
.selectedSkill{
  background: #F7FCFF;
  border: 1px solid #45B4FF;
  border-radius: 8px;
}
.scroll{
  overflow-y: auto !important;
  overflow-x: hidden;
  max-height: calc(100vh - 300px) !important;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 10px !important;
}

</style>
